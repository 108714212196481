import LayoutAdmin from '@/layouts/LayoutAdmin'
import myTable from './table'

export default {
  data() {
    return {
      kata_kunci: null,
      pilih_cif: null,
      search: '',
      loadingTb: false,
      listCif: [],
      tbData: [],
      getData: [],
      setId: null,
      listBalai: [],
      listStatker: [],
      list_usersTypes: [],
      list_userGroup: [],
      listProjek:[],
      isBalai:false,
      isSatker:false,
      isProjek:false,

    }
  },
  created() {
    this.$emit(`update:layout`, LayoutAdmin);
  },
  mounted() {
    this.getTable()

    this.G_GetRef('balai').then(data => {
      this.listBalai = data;
    });

    this.G_GetRef('userType').then(data => {
      this.list_usersTypes = data;
    });

    this.G_GetRef('userGroup').then(data => {
      this.list_userGroup = data;
    });

    this.G_GetRef('proyek').then(data => {
      this.listProjek = data;

      this.list_userGroup.unshift({
        'text': 'proyek',
        'value': 'proyek'
      })
    });

   
  },
  components: {
    myTable
  },
  methods: {
    getTable() {
      this.loadingTb = true
      this.G_GetAny('user').then(res => {
        console.log(res);
        this.loadingTb = false
        this.tbData = res.data
      })
    },
    groupType(type,kdsatker){
      if (type == 'satker') {
        this.isBalai = false
        this.isSatker = true
        this.isProjek = false
        this.G_GetRef('satker').then(data => {
          this.listStatker = data;
        }) .finally(() => {
          this.$refs.kdsatker.setValue(kdsatker)
        });
      }else if(type == 'balai'){
        this.isBalai = true
        this.isSatker = false
        this.isProjek = false
      }
      else if(type == 'direktorat'){
        this.isBalai = false
        this.isSatker = true
        this.isProjek = false
        this.G_GetRef('satker','?type=direktorat').then(data => {
          this.listStatker = data;
        }).finally(() => {
          this.$refs.kdsatker.setValue(kdsatker)
        });
      }
      else if(type == 'proyek'){
        this.isBalai = false
        this.isSatker = false
        this.isProjek = true
      }
      else if(type == 'ditjensda'){
        this.isBalai = false
        this.isSatker = false
        this.isProjek = false
      }
    },
    edit(id) {
      console.log(id);
      this.G_GetAny('user/' + id).then(res => {
        this.getData = res.data
        this.setId = res.data.userId
       

        this.groupType(this.getData.userGroup,res.data.kdsatker)
       

        this.$refs.modal.open("Edit Data User", 600)

        this.$nextTick(() => {

          console.log(res.data);

          this.$refs.usersTypes.setValue(res.data.usersTypes[0])
          this.$refs.userGroup.setValue(res.data.userGroup)
          this.$refs.kdbalai.setValue(res.data.kdbalai)
          // this.$refs.kdsatker.setValue(res.data.kdsatker)
        });
      })
    },
    add() {
      if (this.$refs.form) {
        this.$refs.form.reset()
        this.$refs.form.resetValidation()
      }
      this.setId = null
      this.getData = []
      this.$refs.modal.open("Tambah Data User", 600)

    },
    store() {
      let myForm = document.getElementById('formData');
      let formData = new FormData(myForm);
      formData.append('userStatus','active')

      if (this.setId) {
        formData.append('userId',this.setId)
        
      }else{
        formData.append('password','passwd098')
      }

     
      let arr = []
      arr.push(formData.get('usersTypes'))

      var object = {};
      formData.forEach((value, key) => object[key] = value);

      object['usersTypes'] = arr

      if (this.$refs.form.validate()) {
          this.$refs.modal.loading()

          if (this.setId) {
            this.G_PutAnyCustom('user', object).then(res => {
              if (res) {
                this.getTable();
                this.$snotify.success('Berhasil Mengubah Data');
                this.$refs.modal.close()
              }else{
                this.$snotify.warning('Terjadi Kesalahan');
              }
            })
            .finally(() => {
              this.$refs.modal.loadingEnd()
              
            });
          }else{
            this.G_PostAnyCustom('user', object).then(res => {
              if (res) {
                this.getTable();
                this.$snotify.success('Berhasil Menyimpan Data');
                this.$refs.modal.close()
              }else{
                this.$snotify.warning('Terjadi Kesalahan');
              }
            })
            .finally(() => {
              this.$refs.modal.loadingEnd()
              
            });
          }

         
      }
      else {
        this.$snotify.info('Lengkapi Data Terlebih Dahulu');
      }
    },
    async reset(id) {
      let judul = "Reset Password"
      let subJudul = "Yakin Akan Reset Password User Ini?"

      if (await this.$refs.confirm.open(judul,subJudul) ) {
          let formData = new FormData();
          formData.append('userId',id)
          this.$refs.confirm.loading()
          this.G_PostAny('user/resetPassword', formData).then(res => {
              console.log(res);
              if (res) {
                  this.$refs.confirm.close()
                  this.$refs.modal.close()
                  this.getTable();
                  this.$snotify.success(res.message);
                  // this.$refs.notif.success(res.message,`Berhasil ${judul}`);
              }else{
                this.$snotify.warning(res.message);

                  // this.$refs.notif.warning(res.message,'Terjadi Kesalahan');
              }
          })
          .finally(() => {
              this.$refs.confirm.loadingEnd()
          }); 
      }

    },
    setSatker(id) {
      this.G_GetRef('satker', '?balaiId=' + id).then(data => {
        this.listStatker = data;
      });


    },
  }
}