<template>
    <div>
        <v-data-table :headers="headers" :items="tbData" :single-expand="singleExpand" :search="search"
            :loading="loadingTb" loading-text="Sedang Memuat Data..."  disable-pagination  hide-default-footer :expanded.sync="expanded" item-key="id">

             <template v-slot:item.noRow="{ item }" >
                 {{tbData.map(function(x) {return x.userId; }).indexOf(item.userId)+1}}
             </template>

             <!-- <template v-slot:item.counterOs="{item}">
                <div class="p-2 text-right">
                    {{G_numFormat(item.counterOs)}}
                </div>
            </template>
               <template v-slot:item.clOs="{item}">
                <div class="p-2 text-right">
                    {{G_numFormat(item.clOs)}}
                </div>
            </template>
             <template v-slot:item.selisih="{item}">
                <div class="p-2 text-right">
                    {{G_numFormat(item.selisih)}}
                </div>
            </template> -->

            <template v-slot:item.actions="{ item }">
                <v-menu transition="slide-x-transition" bottom right>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="#fafafa" class="btn-table"  fab depressed x-small v-bind="attrs" v-on="on">
                            <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                    </template>

                    <v-list flat dense elevation="1">
                        <!-- <v-subheader>Action</v-subheader> -->
                        <v-list-item-group color="primary">
                              <v-list-item @click="edit(item.userId)">
                                <v-list-item-icon class="mr-3">
                                    <v-icon small>mdi-pencil</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>Edit</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-item-group>
                            <v-list-item-group color="primary">
                              <v-list-item @click="reset(item.userId)">
                                <v-list-item-icon class="mr-3">
                                    <v-icon small>mdi-key</v-icon>
                                </v-list-item-icon>
                                <v-list-item-content>
                                    <v-list-item-title>Reset Password</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-list-item-group>
                    </v-list>
                </v-menu>
            </template>
        </v-data-table>

      

    </div>
</template>
<script>
    export default {
        data() {
            return {
                 pagination: {
                rowsPerPage: 30
                },
                expanded: [],
                singleExpand: false,
                  headers: [
                    { text: 'No', value: 'noRow',sortable: false,width: "1%" },
                    { text: 'Nama', value: 'name' },
                    { text: 'Username', value: 'username' },
                    { text: 'Group', value: 'userGroup' },
                    { text: 'Tipe User', value: 'usersTypes' },
                    { text: 'Kode Balai', value: 'kdbalai' },
                    { text: 'Nama Balai', value: ' balai.nmbalai' },
                    { text: 'Nama Satker', value: 'satker.satkerNm' },
                    { text: 'Status', value: 'userStatus' },

                    // { text: 'Act', value: 'actions', sortable: false,width: "2%" },
                ],
            }
        },
        mounted(){
           if(this.getUser.usersTypes[0] != 'guest'){
                this.headers.push( { text: 'Act', value: 'actions', sortable: false,width: "2%" })
            }
        },
        methods: {
        
            getTable(){
                this.$emit('getTable');
            },
             edit(id){
                this.$emit('edit',id);
            },
             reset(id){
                this.$emit('reset',id);
            },
          
            
        },

        props: ['tbData', 'search', 'loadingTb'],
    }
</script>